import React from 'react'
import { Spin } from 'antd'

import Layout from '../components/layout'
import SEO from '../components/seo'

const spinnerStyle = {
  display: 'inline-block',
  opacity: 1,
  position: 'absolute',
  top: '50vh',
  left: '50vw'
}

const containerStyle = {
  width: '100%',
  height: '100vh',
  position: 'relative'
}
const NotFoundPage = () => (
  <div style={containerStyle}>
    <SEO title="404: Not found" />
    <Spin style={spinnerStyle}/>
  </div>
)

export default NotFoundPage
